.dots_custom {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  height: 40px;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: rgba(114, 105, 105, 0.18);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background: linear-gradient(
    92.33deg,
    rgba(112, 1, 255, 0.8) 6.14%,
    rgba(193, 92, 255, 0.8) 94.68%
  );
}
