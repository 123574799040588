.modalQr {
  display: none;
  height: auto;
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
}

.modalQr button {
  outline: none;
  cursor: pointer;
  border: 0;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.01);
  font-size: 30px;
}
.modalQr > section {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  max-width: 390px;
  background-color: rgba(0, 0, 0, 0.01);
  animation: modal-show 0.3s;
  position: fixed;
  top: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  border-radius: 15px 15px 0 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}

.modalQr.openModal {
  display: flex;
  align-items: center;
  animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
