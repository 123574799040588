.modal {
  display: none;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
  display: inline-block;
}
.modal > section {
  width: 100%;
  max-width: 390px;
  animation: modal-show 0.3s;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  border-radius: 15px 15px 0 0;
  background-color: white;
}
.modal > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: white;
  font-size: 16px;
  border-radius: 30% 30% 0 0;
}
.modal > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modal > section > main {
  padding: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #646464;
}
.modal > section > footer {
  padding: 12px 16px;
  display: flex;
  justify-content: space-around;
}

.modal > section > footer button {
  padding: 6px 12px;
  color: white;
  background: linear-gradient(92.33deg, #7000ff 6.14%, #c15cff 94.68%);
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  width: 350px;
  height: 60px;
}

.modal.openModal {
  display: flex;
  align-items: center;
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
